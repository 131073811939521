<template>
  <div class="SysData">
    备份恢复数据库
  </div>
</template>

<script>
export default {
  name: 'SysData',
  data () {
    return {

    };
  },
  methods: {}
}
</script>

<style scoped>

</style>