<template>
  <div class="SysAdmin">
    <div class="searchInput">
      <el-input v-model="keywords" placeholder="通过用户名搜索用户..." prefix-icon="el-icon-search" style="width:400px;"></el-input>
      <el-button type="primary" icon="el-icon-search" style="margin-left:8px" @click="doSearch">搜索</el-button>
    </div>
    <div class="admin-container">
      <el-card class="admin-card" v-for="(admin,index) in admins" :key="index">
        <div slot="header" class="clearfix">
          <span>{{admin.name}}</span>
          <el-button style="float: right; padding: 3px 0;color:red;" type="text" icon="el-icon-delete" @click="deleteAdmin(admin)"></el-button>
        </div>
        <div>
          <div class="img-container">
            <img :src="admin.userFace" :alt="admin.name" :title="admin.name" class="userFaceImg"> 
          </div>
        </div>
        <div class="userInfo-container">
          <div>用户名:{{admin.name}}</div>
          <div>手机号码:{{admin.phone}}</div>
          <div>电话号码:{{admin.telephone}}</div>
          <div>用户地址:{{admin.address}}</div>
          <div>用户状态:
            <el-switch
              v-model="admin.enabled"
              active-color="#13ce66"
              inactive-color="#ff4949"
              @change="enabledAdmin(admin)"
              active-text="启用"
              inactive-text="禁用">
            </el-switch>
          </div>
          <div>用户角色:
            <el-tag style="margin-right:4px;" type="success" v-for="(role,indexj) in admin.roles" :key="indexj">{{role.nameZh}}</el-tag>
            <el-popover
              placement="right"
              title="角色列表"
              width="200"
              @show="showRoles(admin)"
              @hide="hidePop(admin)"
              trigger="click">
              <el-select v-model="selectedRoles" multiple placeholder="请选择">
                <el-option
                  v-for="(r,index) in allRoles"
                  :key="index"
                  :label="r.nameZh"
                  :value="r.id">
                </el-option>
              </el-select>
              <el-button slot="reference" type="text" icon="el-icon-more"></el-button>
            </el-popover>           
          </div>
          <div>
            备注:{{admin.remark}}
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SysAdmin',
  data () {
    return {
      admins:[],
      temp:"a.jpg",
      keywords:'',
      allRoles:[],
      selectedRoles:[]
    };
  },
  mounted(){
    this.initAdmins();
  },
  methods: {
    showRoles(admin){
      this.initRoles();
      let roles = admin.roles;
      this.selectedRoles = [];
      roles.forEach(role => {
        this.selectedRoles.push(role.id);
      });
    },
    hidePop(admin){
      let roles= [];
      Object.assign(roles,admin.roles);
      let flag = false;
      if(roles.length != this.selectedRoles.length){
        flag = true; 
      }else{
        for (let i = 0; i < roles.length; i++) {
          let temp = roles[i];
          for (let j = 0; j < this.selectedRoles.length; j++) {
            let sr = this.selectedRoles[j];
            if(temp.id == sr){
              roles.splice(i,1);
              i--;
              break;
            }
          }
        }
        if(roles.length!=0){
          flag = true;
        }
      }
      if(flag){
        let url = '/system/admin/role?adminId='+admin.id;
        this.selectedRoles.forEach(rid=>{
          url += '&rids='+rid;
        });
        this.putRequest(url).then(res=>{
          if(res){
            this.initAdmins();
          }
        });
      }
    },
    initRoles(){
      this.getRequest('/system/admin/roles').then(res=>{
        if(res){
          this.allRoles = res;
        }
      })
    },
    enabledAdmin(admin){
      this.putRequest('/system/admin/',admin).then(res=>{
        if(res){
          this.initAdmins();
        }
      })
    },
    deleteAdmin(admin){
      this.$confirm('此操作将永久删除['+admin.name+']操作员, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.deleteRequest('/system/admin/' + admin.id).then(res=>{
            if(res){
              this.initAdmins();
            }
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
      });
    },
    doSearch(){
      this.initAdmins();
    },
    initAdmins(){
      this.getRequest('/system/admin/?keywords='+this.keywords).then(res=>{
        if(res){
          this.admins = res;
        }
      });
    }
  }
}
</script>

<style scoped>
  .SysAdmin .searchInput{
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  .admin-container{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 10px;
  }
  .admin-container .admin-card{
    width: 350px;
    margin-bottom: 20px;
  }
  .img-container{
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .img-container .userFaceImg{
    width: 72px;
    height: 72px;
    border-radius: 72px;
  }
  .userInfo-container{
    font-size: 12px;
    color: #409eff;
  }
</style>