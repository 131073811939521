<template>
  <div class="PermissMana">
    <div class="permissionManaTool">
      <el-input size="small" placeholder="请输入角色英文名" v-model="role.name" @keydown.enter.native="doAddRole">
        <template slot="prepend">ROLE_</template>
      </el-input>
      <el-input size="small" v-model="role.nameZh" placeholder="请输入角色中文名" @keydown.enter.native="doAddRole"></el-input>
      <el-button size="small" type="primary" icon="el-icon-plus" @click="doAddRole">添加角色</el-button>
    </div>
    <div class="permissionManaMain">
      <el-collapse v-model="activeName" accordion @change="change">
        <el-collapse-item :title="role.nameZh" :name="role.id" v-for="(role, index) in roles" :key="index">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>可访问资源</span>
              <el-button style="float: right; padding: 3px 0; color:red" type="text" icon="el-icon-delete" @click="deleteRole(role)"></el-button>
            </div>
            <div>
              <el-tree 
                show-checkbox
                :data="allMenus" 
                :props="defaultProps"
                :default-checked-keys="selectedMenus"
                ref="tree"
                :key="index"
                node-key="id"></el-tree>
              <div style="display:flex;justify-content:flex-end">
                <el-button size="mini" type="info" @click="cancelUpdate">取消修改</el-button>
                <el-button size="mini" type="primary" @click="doUpdate(role.id,index)">确认修改</el-button>
              </div>
            </div>
          </el-card>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PermissMana',
  data () {
    return {
      role:{
        name:'',
        nameZh:''
      },
      roles:[],
      allMenus:[],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      selectedMenus:[],
      activeName:-1
    };
  },
  mounted(){
    this.initRoles();
  },
  methods: {
    doAddRole(){
      if(this.role.name && this.role.nameZh){
        this.postRequest('/system/basic/role/role',this.role).then(res=>{
          if(res){
            this.role.name = '';
            this.role.nameZh = '';
            this.initRoles();
            this.activeName = -1;
          }
        });
      }else{
        this.$message.error('所有字段不能为空');
      }
    },
    deleteRole(role){
      if(role){
        this.$confirm('此操作将永久删除['+role.nameZh+']角色, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.deleteRequest('/system/basic/role/role/' + role.id).then(res=>{
              if(res){
                this.initRoles();
              }
            });
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除'
            });          
        });
      }
    },
    cancelUpdate(){
      this.activeName = -1;
    },
    doUpdate(rid,index){
      let tree = this.$refs.tree[index];
      let keys = tree.getCheckedKeys(true);
      let url = "/system/basic/role/?rid=" + rid;
      keys.forEach(key => {
        url += '&mids='+key;
      }); 
      this.putRequest(url).then(res=>{
        if(res){
          this.initRoles();
          this.activeName = -1;
        }
      })
    },
    change(rid){
      if(rid){
        this.initAllMenus();
        this.initSelectedMenus(rid);
      }
    },
    initSelectedMenus(rid){
      if(rid){
        this.getRequest('/system/basic/role/mid/'+rid).then(res=>{
          if(res){
            this.selectedMenus = res;
          }
        })
      }
    },
    initAllMenus(){
      this.getRequest('/system/basic/role/menus').then(res=>{
        if(res){
          this.allMenus = res;
        }
      })
    },
    initRoles(){
      this.getRequest('/system/basic/role/').then(res=>{
        if(res){
          this.roles = res;
        }
      })
    }
  }
}
</script>

<style scoped>
  .permissionManaTool{
    display: flex;
    justify-content: flex-start;
  }
  .permissionManaTool .el-input{
    width: 300px;
    margin-right: 8px;
  }
  .permissionManaMain{
    margin-top: 10px;
    width: 700px;
  }
</style>