<template>
  <div class="SysLog">
    操作日志管理
  </div>
</template>

<script>
export default {
  name: 'SysLog',
  data () {
    return {

    };
  },
  methods: {}
}
</script>

<style scoped>

</style>