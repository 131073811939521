<template>
  <div class="DepMana">
    <el-input
      placeholder="输入部门名称进行过滤..."
      prefix-icon="el-icon-search"
      v-model="filterText">
    </el-input>

    <el-tree
      style="margin-top:10px;"
      :data="deps"
      :props="defaultProps"
      :expand-on-click-node="false"
      :filter-node-method="filterNode"
      ref="tree">
      <span class="custom-tree-node" slot-scope="{ data }" style="display:flex;justify-content:space-between;width:100%;">
        <span>{{ data.name }}</span>
        <span>
          <el-button
            type="primary"
            size="mini"
            class="depBtn"
            @click="() => showAddDep(data)">
            添加部门
          </el-button>
          <el-button
            type="danger"
            size="mini"
            class="depBtn"
            @click="() => deleteDep(data)">
            删除部门
          </el-button>
        </span>
      </span>
    </el-tree>
    <el-dialog
      title="添加部门"
      :visible.sync="dialogVisible"
      width="30%">
      <div>
        <table>
          <tr>
            <td>
              <el-tag>上级部门</el-tag>
            </td>
            <td>{{pname}}</td>
          </tr>
          <tr>
            <td>
              <el-tag>添加部门</el-tag>
            </td>
            <td>
              <el-input v-model="dep.name" placeholder="请输入部门名称..."></el-input>
            </td>
          </tr>
        </table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="doAddDep">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'DepMana',
  data () {
    return {
      filterText:'',
      deps:[],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      dialogVisible:false,
      dep:{
        name:'',
        parentId:-1
      },
      pname:''
    };
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  mounted(){
    this.initDeps();
  },
  methods: {
    initDep(){
      this.dep = {
        name:'',
        parentId: -1
      }
      this.pname = '';
    },
    addDep2Deps(deps,dep){
      for(let i=0;i<deps.length;i++){
        let d = deps[i];
        if(d.id == dep.parentId){
          d.children = d.children.concat(dep);
          if(d.children.length >0){
            d.isParent = true;
          }
          return;
        }else{
          this.addDep2Deps(d.children,dep);
        }
      }
    },
    doAddDep(){
      this.postRequest('/system/basic/department/',this.dep).then(res=>{
        if(res){
          this.addDep2Deps(this.deps,res.data);
          this.dialogVisible = false;
          this.initDep();
        }
      })
    },
    showAddDep(data){
      this.pname = data.name;
      this.dep.parentId = data.id;
      this.dialogVisible = true;
    },
    deleteDepFromDeps(p,deps,id){
      for (let index = 0; index < deps.length; index++) {
        let d = deps[index];
        if(d.id == id){
          deps.splice(index,1);
          if(deps.length == 0){
            p.isParent = false;
          }
          return;
        }else{
          this.deleteDepFromDeps(d,d.children,id);
        }
      }
    },
    deleteDep(data){
      if(data.isParent){
        this.$message.error('该部门为父部门，删除失败');
      }else{
        this.$confirm('此操作将永久删除['+data.name+']部门, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.deleteRequest('/system/basic/department/' + data.id).then(res=>{
              if(res){
                this.deleteDepFromDeps(null,this.deps,data.id);
              }
            });
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除'
            });          
        });
      }
    },  
    initDeps(){
      this.getRequest('/system/basic/department/').then(res=>{
        if(res){
          this.deps = res;
        }
      })
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    }
  }
}
</script>

<style scoped>
  .DepMana{
    width: 500px;
  }
  .depBtn{
    padding: 2px;
  }
</style>